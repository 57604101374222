export const VIEWER_HOOKS = {
  PP_WIDGET_SUBTITLE: 'app-subtitle',
  PLAN_RECURRENCE: 'plan-recurrence',
  BENEFITS: 'benefits',
  BENEFIT: 'benefit',
  EXPAND_BENEFITS: 'expand-benefits',
  PLAN_DURATION: 'plan-duration',
  PLAN_PRICE: 'plan-price',
  PLAN_PRICE_SCREEN_READER: 'plan-price-screen-reader',
  PRICE_CURRENCY: 'price-currency',
  PRICE_AMOUNT: 'price-amount',
  PLAN_FREE_TRIAL: 'plan-free-trial',
  PLAN_CTA_WRAPPER: 'plan-cta-wrapper',
  PLAN_CTA: 'plan-cta',
  PLAN_RIBBON: 'ribbon',
  PLAN_TAGLINE: 'plan-tagline',
  PLAN_NAME: 'plan-title',
  PLAN_CTA_LOADER: 'plan-cta-loader',
  ALERT_MODAL: 'alert-modal',
  ALERT_MODAL_TITLE: 'alert-modal-title',
  ALERT_MODAL_SUBTITLE: 'alert-modal-subtitle',
  ALERT_MODAL_BUTTON: 'alert-modal-button',
  UPGRADE_MODAL: 'owner-upgrade-modal',
  UPGRADE_MODAL_CLOSE_BUTTON: 'upgrade-modal-close',
  UPGRADE_MODAL_UPGRADE_BUTTON: 'upgrade-modal-upgrade',
  UPGRADE_MODAL_PREVIEW_BUTTON: 'upgrade-modal-preview',
  EMPTY_STATE: 'empty-state',
  EMPTY_STATE_BUTTON: 'empty-state-button',
  CHECKOUT_LOADER: 'checkout-loader',
  CHECKOUT_GET_PLAN_BUTTON: 'payment-checkout-get-plan',
  CHECKOUT_BUY_NOW_BUTTON: 'payment-checkout-buy-now',
  CHECKOUT_TERMS_AND_CONDITIONS: 'checkout-terms-and-conditions',
  CHECKOUT_TERMS_AND_CONDITIONS_CHECKBOX: 'checkout-terms-and-conditions-checkbox',
  CHECKOUT_TERMS_AND_CONDITIONS_CHECKBOX_TEXT_BUTTON: 'checkout-terms-and-conditions-checkbox-text-button',
  CHECKOUT_TERMS_AND_CONDITIONS_MODAL: 'checkout-terms-and-conditions-modal',
  CHECKOUT_CONTINUE_BUTTON: 'checkout.continue-button',
  TITLE_DIVIDER: 'title-divider',
  STEP_DIVIDER: 'step-divider',
};
