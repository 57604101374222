import React from 'react';

interface CurrencyLocaleContext {
  locale: string | undefined;
}

const CurrencyLocaleContainer = React.createContext<CurrencyLocaleContext>({ locale: undefined });

export const CurrencyLocaleProvider: React.FC<CurrencyLocaleContext> = ({ children, locale }) => {
  return <CurrencyLocaleContainer.Provider value={{ locale }}>{children}</CurrencyLocaleContainer.Provider>;
};

export const useCurrencyLocale = () => React.useContext(CurrencyLocaleContainer);
